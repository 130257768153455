import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import tw, { css } from 'twin.macro'
import SeoPlugin from 'gatsby-plugin-wpgraphql-seo'
import Container from '../../src/components/Container'
import Button from '../../src/components/Button'
import FeaturePost from '../../src/pageBuilder/FeaturePost/FeaturePost'
import BlogTile from '../../src/components/BlogTile/BlogTile'
import Seo from '../../src/components/Seo'

const ArchiveGrid = ({ arr }) => {
  const postsPerClick = 6
  const [numPosts, setNumPosts] = useState(postsPerClick)

  const handleShowMorePosts = () => {
    setNumPosts(numPosts + postsPerClick)
  }

  return (
    <Container variant="wide" tw="py-10 tablet:py-5">
      <div
        css={[
          css`
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
            @media screen and (max-width: 400px) {
              grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            }
          `,
          tw`gap-7`,
        ]}
      >
        {arr?.slice(0, numPosts)?.map((post, index) => (
          <BlogTile
            key={post.node.id}
            title={post.node.title}
            date={post.node.date}
            image={post.node.featuredImage?.node}
            link={post.node.uri}
          />
        ))}
      </div>
      {numPosts < arr?.length && (
        <Button variant="primary" tw="mx-auto mt-5" onClick={handleShowMorePosts}>
          View More
        </Button>
      )}
    </Container>
  )
}

const PostArchiveTemplate = ({ data, pageContext: { catId, catName } }) => {
  const archiveArr = data.allWpPost.edges
  const featuredPost = archiveArr.shift()

  return (
    <>
      {/* <Seo title={data?.allWpPost.edges[0].node.seo?.title} description={data?.allWpPost.edges[0].node.seo?.metaDesc} /> */}
      <SeoPlugin post={data} title={data?.allWpPost.edges[0].node.seo?.title} description={data?.allWpPost.edges[0].node.seo?.metaDesc} />

      <Container variant="wide" tw="pt-10">
        <h1>{catName}</h1>
      </Container>
      <FeaturePost post={featuredPost.node} excerpt date author showCategory excerptWordLength={120} />
      <ArchiveGrid arr={archiveArr} />
    </>
  )
}

export default PostArchiveTemplate

export const pageQuery = graphql`
  query ($catId: String!) {
    allWpPost(filter: { categories: { nodes: { elemMatch: { id: { eq: $catId } } } } }, sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          title
          excerpt
          uri
          slug
          date(formatString: "MMMM DD, YYYY")
          categories {
            nodes {
              name
            }
          }
          seo {
            title
            metaDesc
            readingTime
            focuskw
            metaKeywords
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphImage {
              altText
              sourceUrl
              srcSet
            }
            twitterTitle
            twitterDescription
            twitterImage {
              altText
              sourceUrl
              srcSet
            }
            canonical
            cornerstone
            schema {
              articleType
              pageType
              raw
            }
            breadcrumbs {
              text
              url
            }
          }
          author {
            node {
              name
            }
          }
          featuredImage {
            node {
              altText
              gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 700)
            }
          }
        }
      }
    }
  }
`
